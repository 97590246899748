.navRoot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.navContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.navLinks {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.content {
  padding: 10px;
}
.active_content {
  padding: 10px;
  border-bottom: 1px solid #000;
  cursor: pointer;
}

.content:hover {
  cursor: pointer;
  border-bottom: 1px solid #000;
}

.navLogo {
  padding: 5px;
  cursor: pointer;
  font-size: 24px;
  color: #044368;
  font-weight: bolder;
}

.navButton {
  color: #044368;
  cursor: pointer;
  border: 1px solid #044368;
  border-radius: 5px;
  padding: 10px;
  transition: ease-in-out 0.3s ease;
}

.navButton:hover {
  color: #fff;
  background-color: #044368;
  transition: ease-in-out;
}

#publishers {
  background-color: #ff9800;
  color: white;
  border: 1px solid #ff9800;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
}

#publishers:hover {
  background-color: #fff;
  color: #ff9800;
  border: 1px solid #ff9800;
}

.hide {
  display: none;
}

.navLinksActive {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  margin-left: 100%;
  height: 35vh;
  background-color: #fff;
  z-index: 3;
  width: 110%;
  padding: 10px 100px;
  float: right;
}

.showButton {
  background-color: #1876d1;
  color: white;
  border: 1px solid #1876d1;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.showButton:hover {
  background-color: #fff;
  color: #1876d1;
  border: 1px solid #1876d1;
}
.back-btn {
  color: #092f53;
}
@media (max-width: 600px) {
  .navLinks {
    display: none;
  }
  .button {
    display: none;
  }

  #show_in_mobile {
    display: inline;
  }

  .content:hover {
    border-bottom: none;
  }
}
