* {
  font-family: "Roboto", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.root {
  padding: 0;
  background-color: #f8f8f8;
}
.navbar {
  top: 0px;
  width: 100%;
  position:sticky;
  z-index:2;
  box-shadow: 1px 2px 2px #ecebeb;
  background-color:white
}

.footer {
  position: relative;
  top: 2vh;
  bottom: 0;
  width: 100%;
  display: inline-block;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, .css-qiwgdb.css-qiwgdb.css-qiwgdb{
  padding-right:100px !important;
}
