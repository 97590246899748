#footerRoot {
  background-color: #092f53;
  color: #fff;
  padding: 35px;
  /* margin: 0 -5px; */
}

.footerSubtitle {
  font-size: 16px;
  padding: 5px;
  text-align: left;
  cursor: pointer;
}

.footerText {
  font-size: 14px;
  padding-top: 15px;
  width: 100%;
  text-align: center;
}

.footerElt {
  display: flex;
  flex-direction: row;
}

.terms-privacy {
  cursor: pointer;
  padding: 0 5px;
}

.terms-privacy:hover {
  border-bottom: 1px solid #fff;
}
