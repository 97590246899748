.events-root {
  padding: 5vh 2%;
}

.events-root .not-centered-title {
  padding: 10px 0px;
}

.events {
  overflow: hidden;
}
.tabs-nav {
  display: flex;
  padding-bottom: 5px;
  overflow: auto;
  white-space: nowrap;
}

@media (max-width: 400px) {
  .events {
    grid-template-columns: repeat(1, 1fr);
  }
}
