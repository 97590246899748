.row-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  text-align: center;
  width: 100%;
  font-size: 36px;
  font-weight: 500;
  padding: 10px 0;
}

.not-centered-title {
  width: 100%;
  font-size: 36px;
  font-weight: 500;
  padding: 10px 0;
  /* color:white */
}

.subtitle {
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  color: white;
  opacity: 0.8;
}

.not-centered-subtitle {
  font-size: 15px;
  padding: 10px 0;
  /* padding:0px; */
  /* color: white; */
  opacity: 0.8;
}

.smallTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  max-width: 250px;
  padding: 10px 0;
}
.smallSubtitle {
  text-align: center;
  max-width: 250px;
  font-size: 16px;
  padding: 10px 0;
}

.not-centered-smallTitle {
  font-size: 24px;
  font-weight: 500;
  max-width: 250px;
  padding: 10px 0;
}
.not-centered-smallSubtitle {
  max-width: 250px;
  font-size: 16px;
  padding: 10px 0;
}
.centered_text {
  text-align: center;
}

@media (max-width: 600px) {
  .row-space-around {
    flex-direction: column;
  }

  .row-space-between {
    flex-direction: column;
  }
  .title {
    font-size: 28px;
    font-weight: 500;
  }

  .subtitle {
    text-align: center;
    font-size: 18px;
  }
  .not-centered-smallTitle {
    font-size: 28px;
  }

  .not-centered-title {
    font-size: 27px;
    padding-top: 0px;
    width: 100%;
  }
  .not-centered-subtitle {
    font-size: 18px;
    padding-top: 10px;
    font-weight: 500;
    color: white;
  }

  .smallTitle {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    max-width: 100%;
  }

  .smallSubtitle {
    text-align: center;
    max-width: 100%;
    font-size: 16px;
  }
  .not-centered-smallTitle {
    font-size: 24px;
    font-weight: 500;
    max-width: 100%;
  }
  .not-centered-smallSubtitle {
    max-width: 100%;
    font-size: 16px;
  }
}
