.cardRoot {
  background-color: #fff;
  border-radius: 6px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px;
}

.cardTitle {
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
}
.description-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top:30px;
  width:500px;
}
.contestant-qrCode{
  width:200px;
  height:200px;
}
.contestant-qrCode-mobile{
  display:none
}

.cardSubtitle {
  font-size: 16px;
  padding: 10px;
}
.cart-elt-2 {
  padding: 5vh 10px;
  margin-left: -10%;
  display:flex;
  justify-content:center;
  flex-direction:column
}

#cardImg {
  border-radius: 6px 0 0 6px;
  width: 80%;
}

.b-cardRoot {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  width: 100%;
  height: 25vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.b-cardTitle {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
}

.b-cardSubtitle {
  font-size: 16px;
  padding: 10px 0;
}

#cardImg-2 {
  border-radius: 6px 0 0 6px;
  width: 50%;
}
@media(max-width:900px){
  .cardRoot{
    display:flex;
    flex-direction: column;
  }
  .cart-elt-2{
    padding:0vh 10px;
    align-items: center;
    margin:0px
  }
  #cardImg{
    width:100%;
  }
}

@media (max-width: 600px) {
  .cardRoot {
    grid-template-columns: repeat(1, 1fr);
  }
  .contestant-qrCode-mobile{
  display:block;
}
.contestant-qrCode{
  display:none
}
.description-container{
  width:auto
}

  #cardImg {
    border-radius: 6px 6px 0 0;
  }
  .cart-elt-2 {
    padding:0px;
    margin: 0;
  }

  #cardImg {
    border-radius: 6px 0 0 6px;
    width: 100%;
  }
  .b-cardRoot {
    height: 30vh;
  }
  .b-cardRoot .row-space-between {
    flex-direction: row;
  }

  #cardImg-2 {
    width: 100%;

    border-radius: 6px 6px 0 0;
  }
}
