#introduction {
  padding: 2vh 2%;
}

#introduction img {
  height: 50vh;
  width: 100%;
  border-radius: 3px;
  object-fit: scale-down;
}

#introduction .title {
  font-size: 36px;
  text-align: unset;
  max-width: 500px;
  margin-top: 10vh;
}
.intContent2 {
  width: 60%;
  padding: 1.5vh 5%;
  color: #f0f3f4;
}

@media (max-width: 760px) {
  #introduction {
    padding: 2vh 0;
    margin: 0px;
    width: 100%;
    text-align: center;
  }
  #introduction img {
    height: 40vh;
    display: inline;
    width: 100%;
    object-fit: scale-down;
  }

  #introduction .title {
    margin-top: 10px;
  }

  .imgIntroductionTitle {
    font-size: 32px;
    width: unset;
  }

  .imgIntroduction .button {
    display: inline;
  }
  .intContent2 {
    width: 90%;
    text-align: justify;
    color: #f0f3f4;
  }
}
